<template>
  <Row id="body">
    <!-- 标签-->
    <Col :span="24" class="title">
      <TypographyTitle>{{ $t('nav.name.ChargerManage') }}</TypographyTitle>
    </Col>
    <Col :span="24" class="content">
      <!-- 查询表单-->
      <Row :gutter="[16,32]">
        <Col :span="24">
          <Form :model="searchForm">
            <Row :gutter="16">
              <Col :span="6">
                <FormItem :label="$t('chargerManage.table.identifier')" name="identifier">
                  <Input :placeholder="$t('chargerManage.validate.identifier')" v-model:value="searchForm.identifier"
                         allowClear></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('chargerManage.table.cName')" name="cName">
                  <Input :placeholder="$t('chargerManage.validate.cName')" v-model:value="searchForm.cName"
                         allowClear></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('chargerManage.table.cPort')" name="cPort">
                  <InputNumber
                      :placeholder="$t('chargerManage.validate.cPort')"
                      v-model:value="searchForm.cPort"
                      min="0"
                      max="99999"
                      style="width: 100%"
                  >
                  </InputNumber>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('chargerManage.table.cPower')" name="cPower">
                  <InputNumber
                      :placeholder="$t('chargerManage.validate.cPower')"
                      v-model:value="searchForm.cPower"
                      min="0"
                      max="99999"
                      style="width: 100%"
                  >
                  </InputNumber>
                </FormItem>
              </Col>
            </Row>
            <Row :gutter="16">
              <Col :span="6">
                <FormItem :label="$t('chargerManage.table.cType')" name="cType">
                  <Select
                      v-model:value="searchForm.cType"
                      :placeholder="$t('chargerManage.validate.cType')"
                      :options="selectList.chargingPortTypeList"
                      :dropdownStyle="{ zIndex: 9999999950 }"
                  />
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('chargerManage.table.cStatus')">
                  <Select
                      v-model:value="searchForm.cStatus"
                      :placeholder="$t('chargerManage.validate.cStatus')"
                      :options="selectList.statusList"
                      :dropdownStyle="{ zIndex: 9999999950 }"
                  />
                </FormItem>
              </Col>
              <Col :span="6"></Col>
              <Col :span="6"></Col>
            </Row>
          </Form>
          <Row style="margin-bottom: 5px">
            <Col :span="8">
              <Button v-if="checkPermission('ChargerManage.Add')" type="primary" size="default" @click="add()">{{ $t("public.name.add") }}</Button>
              <span>&nbsp;</span>
              <Button v-if="checkPermission('ChargerManage.Delete')" type="primary" size="default" danger @click="delByIds()">{{ $t("public.name.dels") }}</Button>
            </Col>
            <Col :span="8" :offset="8" style="text-align: right;">
              <Button type="primary" @click="search_submit">{{ $t('public.name.search') }}</Button>
              <span>&nbsp;</span>
              <Button @click="search_reset">{{ $t('public.name.reset') }}</Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <!-- 列表-->
      <Row>
        <Col :span="24">
          <Table
              :row-selection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
              :columns="columns"
              :dataSource="list"
              rowKey="id"
              :loading="loading"
              :scroll="{ x: 1500, y: 'calc(100vh - 455px)' }"
              :pagination="tablePagination"
              @change="chang_page">

            <template #headerCell="{ column }">
              <template v-if="column.dataIndex === 'action'">
              <span>
                {{ $t("public.table.action") }}
                <Button v-if="checkPermission('ChargerManage.Add')" type="primary" size="small" shape="circle" @click="add()">
                  <PlusOutlined/>
                </Button>
              </span>
              </template>
            </template>

            <template #bodyCell="{column, record}">
              <template v-if="column.dataIndex === 'cStatus'">
                <div>
                  <Tag v-if="record.cStatus === 7 || record.cStatus === 9" color="#f50">
                    {{ selectList.statusList.find(item => item.value === record.cStatus).label }}
                  </Tag>
                  <Tag v-else color="#87d068">{{ selectList.statusList.find(item => item.value === record.cStatus).label }}</Tag>
                </div>
              </template>
              <template v-if="column.dataIndex === 'action'">
                <div class="pc-display">
                  <Dropdown :trigger="['click']" @click.prevent>
                    <a>
                      {{ $t("public.name.setting") }}
                      <DownOutlined/>
                    </a>
                    <template #overlay>
                      <Menu>
                        <MenuItem>
                          <Button v-if="checkPermission('ChargerManage.Edit')" type="primary" size="small" @click="edit(record)">{{ $t("public.name.edit") }}</Button>
                        </MenuItem>
                        <MenuItem>
                          <Button v-if="checkPermission('ChargerManage.Delete')" type="primary" danger size="small" @click="del(record)">{{ $t("public.name.del") }}</Button>
                        </MenuItem>
                        <MenuItem>
                          <Button v-if="checkPermission('ChargerManage.StartConn')" type="primary" danger size="small" @click="startConn(record)">{{ $t("chargerManage.name.start") }}</Button>
                        </MenuItem>
                        <MenuItem>
                          <Button v-if="checkPermission('ChargerManage.StartConn')" type="primary" danger size="small" @click="setChargingProfile(record)">{{ $t("chargerManage.name.setChargingProfile") }}</Button>
                        </MenuItem>
                        <MenuItem>
                          <Button v-if="checkPermission('ChargerManage.StartConn')" type="primary" danger size="small" @click="clearChargingProfile(record)">{{ $t("chargerManage.name.clearChargingProfile") }}</Button>
                        </MenuItem>
                        <MenuItem>
                          <Button v-if="checkPermission('ChargerManage.StopConn')" type="primary" danger size="small" @click="stopConn(record)">{{ $t("chargerManage.name.stop") }}</Button>
                        </MenuItem>
                      </Menu>
                    </template>
                  </Dropdown>

                  <Dropdown :trigger="['click']" @click.prevent>
                    <a>
                      触发
                      <DownOutlined/>
                    </a>
                    <template #overlay>
                      <Menu>
                        <MenuItem>
                          <Button type="primary" size="small" @click="triggerMessage(record.identifier, 'MeterValues', record.cPort)">MeterValues</Button>
                        </MenuItem>
                        <MenuItem>
                          <Button type="primary" size="small" @click="triggerMessage(record.identifier, 'StatusNotification', record.cPort)">StatusNotification</Button>
                        </MenuItem>
                      </Menu>
                    </template>
                  </Dropdown>
                </div>
              </template>
            </template>
          </Table>
        </Col>
      </Row>
    </Col>
    <EditModel ref="refEditModel" @updateList="get_list"></EditModel>
    <StartTransaction ref="refStartTransaction" @updateList="get_list"></StartTransaction>
    <SetChargingProfile ref="refSetChargingProfile" @updateList="get_list"></SetChargingProfile>
  </Row>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "chargerManage",
}
</script>
<script setup>
import {createVNode, ref} from "vue";
import {
  Button,
  Col,
  Dropdown,
  Form,
  FormItem,
  TypographyTitle,
  Input,
  InputNumber,
  Menu,
  MenuItem,
  message,
  Modal,
  Row,
  Select,
  Table,
  Tag,
} from "ant-design-vue";

import {useI18n} from "vue-i18n";

import {DownOutlined, ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons-vue";

import {ChargerGetListByPage, ChargerDelInfo, ChargerStartConn, ChargerStopConn, ChargerClearChargingProfile} from "@/api/charger";

import EditModel from "./EditModel";
import StartTransaction from "./StartTransaction";
import SetChargingProfile from "./SetChargingProfile";
import {PileTriggerMessage} from "@/api/pile";

const i18n = useI18n()
let refEditModel = ref()
let refStartTransaction = ref()
let refSetChargingProfile = ref()

let startConnButton = ref(false)
let clearChargingProfileButton = ref(false)
let stopConnButton = ref(false)
let searchForm = ref({})
let tablePagination = ref({
  pageSizeOptions: ["10", "20", "50", "100"],
  showSizeChanger: true,
  current: 1,
  pageSize: 10,
  total: 0,
})
let loading = ref(false)
let list = ref([])
let selectedRowKeys = ref([])
const columns = [
  // {
  //   title: i18n.t("public.name.index"),
  //   fixed: "left",
  //   width: 60,
  //   ellipsis: true,
  //   customRender: ({text, record, index, column}) => `${(tablePagination.value.current-1) * tablePagination.value.pageSize +  index + 1}`
  // },
  {
    title: i18n.t("public.name.ID"),
    width: 60,
    ellipsis: true,
    dataIndex: "id",
    key: "id"
  },
  // {
  //   title: i18n.t("chargerManage.table.identifier"),
  //   width: 180,
  //   ellipsis: true,
  //   dataIndex: "identifier",
  //   key: "identifier"
  // },
  {title: i18n.t("chargerManage.table.cName"), width: 180, ellipsis: true, dataIndex: "cName", key: "cName"},
  // {title: i18n.t("chargerManage.table.cPort"), width: 100, ellipsis: true, dataIndex: "cPort", key: "cPort"},
  {title: i18n.t("chargerManage.table.cPower"), width: 120, ellipsis: true, dataIndex: "cPower", key: "cPower"},
  {
    title: i18n.t("chargerManage.table.cType"),
    fixed: "center",
    width: 120,
    ellipsis: true,
    dataIndex: "cType",
    key: "cType",
    customRender: ({text}) => {
      const obj = selectList.chargingPortTypeList.find(item => item.value === text)
      return obj === undefined ? text : obj.label
    }
  },
  {
    title: i18n.t("chargerManage.table.cStatus"),
    width: 120,
    ellipsis: true,
    dataIndex: "cStatus",
    key: "cStatus"
  },
  {title: "TransactionID", width: 80, ellipsis: true, dataIndex: "transactionid", key: "transactionid"},
  {dataIndex: "action", key: "action", fixed: "right", width: 80, ellipsis: true},
]
const selectList = {
  chargingStationList: [],
  chargingPortTypeList: [
    {value: '0', label: 'Type 1'},
    {value: '1', label: 'Type 2'},
    {value: '2', label: 'CHAdeMO'},
    {value: '3', label: 'CCS Combo Type 1'},
    {value: '4', label: 'CCS Combo Type 2'},
  ],
  statusList: [
    {value: 0, label: 'Available'},
    {value: 1, label: 'Preparing'},
    {value: 2, label: 'Charging'},
    {value: 3, label: 'SuspendedEVSE'},
    {value: 4, label: 'SuspendedEV'},
    {value: 5, label: 'Finishing'},
    {value: 6, label: 'Reserved'},
    {value: 7, label: 'Unavailable'},
    {value: 8, label: 'Faulted'},
    {value: 9, label: 'Disconnect'}
  ],
}

function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list();
}

function get_list() {
  loading.value = true;
  const api = new ChargerGetListByPage();
  // 构建查询参数
  const getData = {
    ...searchForm.value,
    page: tablePagination.value.current,
    limit: tablePagination.value.pageSize,
  }
  api.get(getData).then((response) => {
    loading.value = false;
    if (0 === parseInt(response.data.code)) {
      const dataTmp = response.data.data;
      tablePagination.value.current = parseInt(dataTmp.current);
      tablePagination.value.pageSize = parseInt(dataTmp.pageSize);
      tablePagination.value.total = parseInt(dataTmp.total);
      list.value = dataTmp.data
    } else {
      list.value = [];
    }
  });
}

function add() {
  refEditModel.value?.addShow();
}

function edit(obj) {
  refEditModel.value?.editShow(obj);
}

function del(obj) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new ChargerDelInfo();
      let l = []
      l.push(obj.id)
      api.post({ids: l}).then((response) => {
        parseInt(response.data.code) === 0 ? message.success(response.data.msg) : message.error(response.data.msg);
        // 刷新列表
        get_list();
      });
    },
  });
}

// function startConn(obj) {
//   // 防止重复点击
//   if (startConnButton.value) {
//     message.error('操作过于频繁');
//     return
//   }
//   startConnButton.value = true;
//   loading.value = true
//   const api = new ChargerStartConn();
//   api.get({identifier: obj.identifier, port: obj.cPort}).then((res) => {
//     loading.value = false
//     if (0 === parseInt(res.data.code)) {
//       get_list();
//       message.success(res.data.msg);
//     } else {
//       get_list();
//       message.error(res.data.msg);
//     }
//   })

//   // 延迟执行
//   setTimeout(() => {
//     startConnButton.value = false
//   }, 1000);
// }
function startConn(obj) {
  refStartTransaction.value?.editShow(obj.identifier, obj.cPort);
}

function setChargingProfile(obj) {
  refSetChargingProfile.value?.editShow(obj.identifier, obj.cPort);
}

function clearChargingProfile(obj) {
  // 防止重复点击
  if (clearChargingProfileButton.value) {
    message.error('操作过于频繁');
    return
  }

  clearChargingProfileButton.value = true;
  loading.value = true
  const api = new ChargerClearChargingProfile();
  api.get({identifier: obj.identifier, port: obj.cPort}).then((res) => {
    loading.value = false
    if (0 === parseInt(res.data.code)) {
      get_list();
      message.success(res.data.msg);
    } else {
      get_list();
      message.error(res.data.msg);
    }
  })
  // 延迟执行
  setTimeout(() => {
    clearChargingProfileButton.value = false
  }, 1000);
}

function stopConn(obj) {
  // 防止重复点击
  if (stopConnButton.value) {
    message.error('操作过于频繁');
    return
  }

  stopConnButton.value = true;
  loading.value = true
  const api = new ChargerStopConn();
  api.get({identifier: obj.identifier, port: obj.cPort}).then((res) => {
    loading.value = false
    if (0 === parseInt(res.data.code)) {
      get_list();
      message.success(res.data.msg);
    } else {
      get_list();
      message.error(res.data.msg);
    }
  })
  // 延迟执行
  setTimeout(() => {
    stopConnButton.value = false
  }, 1000);
}

function onSelectChange(ids) {
  selectedRowKeys.value = ids
}

function delByIds() {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new ChargerDelInfo();
      api.post({ids: selectedRowKeys.value}).then((response) => {
        parseInt(response.data.code) === 0 ? message.success(response.data.msg) : message.error(response.data.msg);
        // 刷新列表
        get_list();
      });
    },
  });
}

// 触发电桩上报
function triggerMessage(identifier, trigger, connectorId) {
  const api = new PileTriggerMessage()
  api.post({identifier: identifier, trigger: trigger, connectorId: connectorId}).then((response) => {
    if (parseInt(response.data.code) === 0) {
      response.data.data.status === 'Accepted' ? message.success(response.data.data.status) : message.error(response.data.data.status);
    } else {
      message.error(response.data.msg)
    }
  });
}


function search_submit() {
  tablePagination.value.current = 1
  get_list()
}

function search_reset() {
  searchForm.value = {}
  get_list()
}

get_list()

</script>

<style lang="less">
@import url("style");
</style>
