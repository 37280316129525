import {Base} from "@/api/Base"

const api = "charger"

// 分页查询列表
export class ChargerGetListByPage extends Base {
    path = api + "/getListByPage"
}

// 获取记录
export class ChargerGetInfo extends Base {
    path = api + "/getInfo"
}

// 新增记录
export class ChargerAddInfo extends Base {
    path = api + "/addInfo"
}

// 修改记录
export class ChargerEditInfo extends Base {
    path = api + "/editInfo"
}

// 删除记录
export class ChargerDelInfo extends Base {
    path = api + "/delInfo"
}

// 开始充电
export class ChargerStartConn extends Base {
    path = api + "/startConn"
}

// 结束充电
export class ChargerStopConn extends Base {
    path = api + "/stopConn"
}

// 获取统计数据
export class ChargerGetDashboard extends Base {
    path = api + "/getDashboard"
}

// 设置充电电流
export class ChargerSetChargingProfile extends Base {
  path = api + "/setChargingProfile"
}

// 清除充电电流
export class ChargerClearChargingProfile extends Base {
  path = api + "/clearChargingProfile"
}

